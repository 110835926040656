import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import GlobalState from '../../state/GlobalState';
import { Drawer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import SidebarContents from './SidebarContents';

export default observer(() => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
      anchor={'left'}
      open={GlobalState.menuOpen}
      onClose={() => GlobalState.closeMenu()}
    >
      <SidebarContents />
    </Drawer>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => GlobalState.openMenu()}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Gyve.<b>Me</b>
          </Typography>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    </Box>
  )});

