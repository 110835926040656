import React from 'react'
import Menu from './Menu/Menu'
import { Outlet } from 'react-router-dom'
import { Container, useTheme } from '@mui/material'

const Layout: React.FC<{children?: any}> = (props) => {
    const theme = useTheme()
    return (
        <>
            <Menu />
            <Container style={{marginTop: theme.spacing(2)}}>
                <Outlet />
            </Container>
        </>
    )
}

export default Layout