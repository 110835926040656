import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'

interface CreatorProps {
    path?: string
}

const Creator: React.FC<CreatorProps> = () => {
    return (
        <Grid container>
            <Typography>
                Creator Overview
            </Typography>
            <Link to="/">Home</Link>
        </Grid>
    )
}

export default Creator
