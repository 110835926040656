import React from 'react';
import { PageRouter } from './router';

function App() {
  return (
      <PageRouter />
  );
}

export default App;
