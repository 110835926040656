import React from 'react'
import Home from './pages/Home/Home'
import Creator from './pages/Creator/Creator'
import NewCreator from './pages/Creator/NewCreator'
import NotFound from './pages/NotFound/NotFound'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Layout from './components/Layout'

export const PageRouter = () => {
    const routes = createBrowserRouter([
        { element: <Layout />,
            children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/creators/new",
            element: <NewCreator />
        },
        {
            path: "/:creatorId",
            element: <Creator />
        },
        {
            path: "*",
            element: <NotFound />
        }]}
    ])
    return <RouterProvider router={routes} />
}
