import React, {useState, MouseEvent} from 'react'
import Button from '@mui/material/Button'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Typography, Grid, useTheme } from '@mui/material'

const Home: React.FC<{path?: string}> = () => {
    const theme = useTheme()
    const [state, setState] = useState("")
    const doNavigate = useNavigate()
    const submitForm = (e: MouseEvent) => {
        e.preventDefault()
        const converted = Number(state)
        if(converted !== 0) {
            alert("You're sending " + converted)
            axios.post("https://cc-demo.ip-cloud.nl/buy", {amount: converted})
            .then(res => doNavigate(res.data.payment))
            .catch((error) => alert(error))
        }
        else {
            alert("Please enter a number")
        }
    }
    return (
        <div>
            <Grid container justifyContent='center' alignItems="center" direction='column'>
            <Typography>Welcome to</Typography>
            <Typography color='primary' variant='h1'>Gyve.<b>Me</b></Typography>
            <Typography>The no1 platform to Gyve to your favorite creators using Crypto!</Typography>
            <Typography variant='h6' style={{paddingTop: theme.spacing(2)}}>I want to gyve</Typography>
            <input value={state} onChange={x => setState(x.target.value)} type="number" placeholder="20 GBP" />
            <Button onClick={submitForm} variant='contained' style={{marginTop: theme.spacing()}}>Gyve Now</Button>
            </Grid>
        </div>
    )
}

export default Home
