import {action, makeObservable, observable} from 'mobx'

class GlobalState {
    menuOpen: boolean
    constructor() {
        makeObservable(this, {
            menuOpen: observable,
            openMenu: action,
            closeMenu: action
        })
        this.menuOpen = false
    }

    openMenu() {
        this.menuOpen = true
    }

    closeMenu() {
        this.menuOpen = false
    }
}

const AppState = new GlobalState()

export default AppState