import { Grid, Typography } from '@mui/material'
import React from 'react'

const NotFound: React.FC<{default?: true}> = () => {
    return (
        <Grid container direction={'column'}>
            <Typography variant='h1'>404</Typography>
            <Typography>Page Not Found</Typography>
        </Grid>
    )
}

export default NotFound
