import React from 'react'
import GlobalState from '../../state/GlobalState'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const SidebarContents: React.FC = () => {
    return(
        <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={() => GlobalState.closeMenu()}
        onKeyDown={() => GlobalState.closeMenu()}
        component={Grid}
      >
        <List>
            <Link to="/">
              <ListItem disablePadding>
                  <ListItemButton>
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home"/>
                  </ListItemButton>
              </ListItem>
            </Link>
            <Link to="/creators/new">
              <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Up"/>
                  </ListItemButton>
              </ListItem>
            </Link>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    )
}

export default SidebarContents