import { Grid, TextField, Box } from '@mui/material'
import axios from 'axios'
import { observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

const NewCreatorState = observable({
    name: "",
    display_name: "",
    nameInUse: false,
    supportingText: "",
    result: {}
})

const NewCreator: React.FC = observer(() => {

    const checkUsernameAvailability = () => {
        axios.post("https://cc-demo.ip-cloud.nl/creators/check", {"id": NewCreatorState.name})
        .then((res) => {
            NewCreatorState.nameInUse = false
        })
        .catch((err) => {
            if(err.response && err.response.status === 409) {
                NewCreatorState.supportingText = err.response.data.message
                NewCreatorState.nameInUse = true
            }})
    }

    return (
        <Grid container direction='column'>
            <div>New Creator</div>
            <Box component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
            >
                <TextField label="Your username" value={NewCreatorState.name} onChange={(e) => {
                    e.preventDefault()
                    NewCreatorState.name = e.target.value.toLowerCase()
                }} onBlur={() => checkUsernameAvailability()}
                fullWidth
                helperText={NewCreatorState.nameInUse ? NewCreatorState.supportingText : null}
                error={NewCreatorState.nameInUse}
                />
                <TextField label="Display Name" value={NewCreatorState.display_name} onChange={(e) => {
                    e.preventDefault()
                    NewCreatorState.display_name = e.target.value
                }} helperText={"The name shown at the top of your profile"}
                />
            </Box>
        </Grid>
    )
})

export default NewCreator